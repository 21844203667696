import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import CourseLogos from '../components/course/courseLogos';

const Logos = () => {
  return (
    <>
      <SEO title="Logos" />
      <Layout>
        <CourseLogos />
      </Layout>
    </>
  );
};

export default Logos;
