import React from 'react';
import { Row, Col } from 'antd';

import Styles from './course-ind.module.css';
import HeroLogos from '../image-components/hero/heroLogos';
import TabAbout from '../image-components/tab/tabAbout';
import TabOverview from '../image-components/tab/tabOverview';
import TabGuide from '../image-components/tab/tabGuide';

import GuideImg from '../../images/guide-logos.jpeg';

const CourseLogos = () => {
  return (
    <>
      <HeroLogos />
      <Row className={Styles.row}>
        <Col className={Styles.col_left} sm={24} md={16}>
          <div className={Styles.heading}>LOGOS ADVANCE</div>
          <div className={Styles.body}>
            <div className={Styles.subtitle}>
              <div>Being Transformed via the Word of God.</div>
              <p>
                So many of us wish to be transformed by God but do not immerse
                ourselves in the word of God. So we try to conform God to
                our narratives rather than allow God’s narratives to shape us.
                Logos Advance is about falling in love with the God of the Bible
                and becoming more like Christ. It is for people at all stages of
                faith. For those unfamiliar with the Bible, Logos will make
                God's word more accessible and livable. For those who have grown
                up reading the Bible, Logos will re-awaken your passion for
                God’s Word.
              </p>
            </div>
          </div>
          <div className={Styles.body}>
            <div className={Styles.quote}>
              "We don't form our personal spiritual lives out of a random
              assemblage of favorite texts in combination with individual
              circumstances; we are formed by the Holy Spirit in accordance with
              the text of Holy Scripture. God does not put us in charge of
              forming our personal spiritualities. We grow in accordance with
              the revealed Word implanted in us by the Spirit...the Bible, all
              of it, is livable; it is the text for living our lives. It reveals
              a God-created, God-ordered, God-blessed world in which we find
              ourselves at home and whole."
            </div>
            <div className={Styles.author}>EUGENE H. PETERSON</div>
          </div>
        </Col>
        <Col className={Styles.col_right} sm={24} md={8}>
          <TabAbout>
            <div className={Styles.modal_title}>ABOUT</div>
            <div className={Styles.modal_body}>
              <h2 className={Styles.modal_subtitle}>The Learning Outcomes</h2>
              <p className={Styles.subheading}>Advance participants will:</p>
              <ol>
                <li>
                  Participants will get an overview of all the books of the
                  Bible and its different genres (types of stories).
                </li>
                <li>
                  Participants will learn how to appreciate and observe the
                  contours of select biblical texts.
                </li>
                <li>
                  Participants will practice daily rhythms of reading the Bible.
                </li>
              </ol>
              <h2 className={Styles.modal_subtitle}>The Big Idea</h2>
              <p className={Styles.subheading}>Cohort Based</p>
              <p>
                Ekko's Logos Advance organizes participants to journey together
                through the entire program. In the duration of six weeks,
                participants’ primary learning and practice come from their
                willingness to be humble and teachable throughout what can be a
                mysterious process. In so doing, they learn largely from each
                other, gaining stories with which to tell others about God's
                faithfulness.
              </p>
              <p className={Styles.subheading}>Advisor-Directed</p>
              <p>
                Logos Advance is paired with a guide (Janette Ok) and assistants
                who will facilitate the weekly meetings.
              </p>
              <p className={Styles.subheading}>Online & Hybrid</p>
              <p>
                Logos Advance engages in a variety of venues that are
                EPIC—experiential, participatory, image-driven and connective.
                It does not encourage simply downloading information; instead,
                participants and advisors value relationship, the dynamic
                interaction, and experimentation, which will enrich and deepen
                its outcome. The format comprise of weekly discussions online
                and weekly face-to-face meetings with group members.
              </p>
            </div>
          </TabAbout>
          <TabOverview>
            <div className={Styles.modal_title}>OVERVIEW</div>
            <div className={Styles.modal_body}>
              <div className={Styles.modal_paragraph}>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Logos/Logos%20Syllabus%20S2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={Styles.btn_syllabus}>Syllabus</button>
                </a>
                <div className={Styles.modal_subtitle}>Week 1</div>
                <div className={Styles.subheading}>Representatives of God</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 1</div>
                <div className={Styles.subheading}>
                  The Bible as Scripture & Story
                </div>
                <div className={Styles.subheading}>
                  The Beginning of the Story: Genesis - Deuteronomy
                </div>
                <div></div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 2</div>
                <div className={Styles.subheading}>
                  The Story of Israel in (and out of) the Land: Joshua - Esther
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 3</div>
                <div className={Styles.subheading}>
                  The Witness of Israel's Poets & Sages: Job - Song of Songs
                </div>
                <div className={Styles.subheading}>
                  The Witness of Israel's Prophets: Isaiah - Malachi
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 4</div>
                <div className={Styles.subheading}>
                  The Story of Jesus: The Four Gospels
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 5</div>
                <div className={Styles.subheading}>
                  The Story of the Church: Acts & The Letters
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 6</div>
                <div className={Styles.subheading}>
                  The End of the Story: Revelation
                </div>
              </div>
            </div>
          </TabOverview>
          <TabGuide>
            <div className={Styles.modal_title}>GUIDE</div>
            <div className={Styles.modal_body}>
              <img
                className={Styles.guide_img}
                src={GuideImg}
                alt="Pastor Janette"
              />
              <div className={Styles.guide_name}>Janette Ok</div>
              <div className={Styles.guide_subheading}>
                <div>Pastor of Ekko Church</div>
                <div>Logos Advance Guide</div>
              </div>
              <p>Bio coming soon!</p>
            </div>
          </TabGuide>
        </Col>
      </Row>
    </>
  );
};

export default CourseLogos;
